import React from 'react'

import Icon from '../../../components/Icon'

import dataJson from './data.json'

import { SectionWrapper, Card } from './style'

type dataJsonProps = {
  icon: string;
  title: string;
  text: string;
}

const Consistencia = () => {
  return (
    <SectionWrapper id='consistencia' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <h3 className='f-sora fs-20 lh-25 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-400'>
              Consistência e resultados para você
            </h3>
            <p className='fs-14 lh-16 fs-lg-18 lh-lg-22 text-white mr-xl-5 fw-400'>
              Somos uma empresa orientada pelas necessidade dos nossos clientes e trabalhamos de forma independente, sempre alinhados 
              com seus interesses em busca dos melhores resultados no longo prazo. 
            </p>
          </div>
          <div className='col-12 col-md-6'>
            {
              dataJson.map((item: dataJsonProps) => (
                <Card key={item.title}>
                  <div className='mr-2'>
                    <Icon width='36' height='36' color='orange-medium' icon={item.icon} directory='v2' />
                  </div>
                  <div>
                    <span className='fs-12 lh-15 fw-700'>{item.title}</span>
                    <p className='fs-12 lh-14 fw-400 m-0'>{item.text}</p>
                  </div>
                </Card>
              ))
            }
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default Consistencia
