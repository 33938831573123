import styled from 'styled-components'

 export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  background: #172740;
 `

export const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-radius: 8px;
  color: #6A6C72;
  padding: 20px 10px;

  & + div {
    margin-top: 25px;
  }
`
